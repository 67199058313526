import React, { useState } from "react";
import { Button, Modal, Space } from "antd";

import "./RemoveProduct.css";

const RemoveProduct = ({ handleRemoveProduct }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="RemoveProduct">
      <Button type="primary" size="large" onClick={() => showModal()} danger>
        Удалить
      </Button>
      <Modal
        title="Вы уверены, что хотите удалить этот товар?"
        open={isModalOpen}
        onCancel={handleCancel}
        maskClosable={false}
        footer={false}
      >
        <div className="RemoveProduct-content">
          <Space wrap>
            <Button type="primary" size="large" onClick={() => handleCancel()}>
              Отменить
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={() => handleRemoveProduct()}
              danger
            >
              Удалить
            </Button>
          </Space>
        </div>
      </Modal>
    </div>
  );
};

export default RemoveProduct;
