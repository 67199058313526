import React, { useEffect, useState } from "react";
import { Button, Card, Image, Input, Select, Space, notification } from "antd";
import axios from "axios";

import "./Product.css";
import { API_SERVER } from "../constants/api";
import RemoveProduct from "./components/RemoveProduct";

const Product = () => {
  const [api, contextHolder] = notification.useNotification();
  const [products, setProducts] = useState([]);
  const [categoryActive, setCategoryActive] = useState("1");
  const [updateProduct, setUpdateProduct] = useState({
    id: "",
    text: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios.get(`${API_SERVER}/product/${categoryActive}`).then((res) => {
      setProducts(res.data);
    });
  }, [categoryActive]);

  const handleChangeCategory = (value) => {
    setCategoryActive(value);
  };

  const openNotification = (type, msg, text) => {
    api[type]({
      message: msg,
      description: text,
    });
  };

  const handleDescriptionChange = (id, newDescription) => {
    setUpdateProduct({
      id,
      text: newDescription,
    });
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === id
          ? { ...product, description: newDescription }
          : product
      )
    );
  };

  const handleUpdateProduct = () => {
    setLoading(true);
    axios
      .put(`${API_SERVER}/product`, updateProduct)
      .then((res) => {
        setLoading(false);
        openNotification(
          "success",
          "Уведомление",
          "Продукт успешно отредактирован"
        );
      })
      .catch((e) => {
        openNotification(
          "error",
          "Уведомление об ошибке",
          "Упс) , Что-то пошло не так"
        );
      });
  };

  const handleRemoveProduct = (productId) => {
    axios
      .delete(`${API_SERVER}/product/${productId}`)
      .then((res) => {
        setProducts((prev) => prev.filter((e) => e.id !== productId));
        openNotification("success", "Уведомление", "Продукт успешно удаленно");
      })
      .catch((e) => {
        openNotification(
          "error",
          "Уведомление об ошибке",
          "Упс) , Что-то пошло не так"
        );
      });
  };

  return (
    <div className="Product">
      {contextHolder}
      <div className="Product-container">
        <Select
          defaultValue={categoryActive}
          value={categoryActive}
          style={{
            width: "100%",
          }}
          onChange={handleChangeCategory}
          size="large"
          options={[
            {
              value: "1",
              label: "Мужская одежда",
            },
            {
              value: "2",
              label: "Женская одежда",
            },
            {
              value: "3",
              label: "Детская одежда",
            },
            {
              value: "4",
              label: "Мужская обувь",
            },
            {
              value: "5",
              label: "Женская обувь",
            },
            {
              value: "6",
              label: "Детская обувь",
            },
            {
              value: "7",
              label: "Женские сумки",
            },
            {
              value: "8",
              label: "Платки",
            },
            {
              value: "9",
              label: "Домашний текстиль",
            },
            {
              value: "10",
              label: "Джинсы",
            },
            {
              value: "11",
              label: "Люкс одежа",
            },
            {
              value: "12",
              label: "Кожа",
            },
            {
              value: "13",
              label: "Спортивные костюмы",
            },
          ]}
        />
        <div className="products">
          {products.length ? (
            products?.map((product) => {
              return (
                <Card
                  title={false}
                  bordered={false}
                  style={{
                    width: "90%",
                    marginTop: 24,
                  }}
                  key={product.id}
                >
                  <div className="product-image">
                    {JSON.parse(product?.images)?.map((elm) => {
                      return (
                        <Image
                          width={80}
                          src={`${API_SERVER}/image/${elm.media}`}
                        />
                      );
                    })}
                  </div>

                  <div className="product-desс">
                    <Input.TextArea
                      value={product?.description || ""}
                      onChange={(e) =>
                        handleDescriptionChange(product.id, e.target.value)
                      }
                      rows={8}
                      showCount
                    />
                  </div>

                  <div className="prodcut-action">
                    <Space wrap>
                      <Button
                        type="primary"
                        size="large"
                        onClick={() => handleUpdateProduct()}
                        loading={loading}
                      >
                        Сохранить
                      </Button>
                      <RemoveProduct
                        handleRemoveProduct={() =>
                          handleRemoveProduct(product.id)
                        }
                      />
                    </Space>
                  </div>
                </Card>
              );
            })
          ) : (
            <div className="notfound">Ничего не найдено</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Product;
